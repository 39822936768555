<template>
    <l-marker :lat-lng="getLatLng(poi.coordinates)" :visible="visible">
        <l-icon class-name="map-marker">
            <i :class="poi.icon"></i>
        </l-icon>
        <l-popup>
            <div>
                <h4>{{ poi.name }}</h4>
                <p>{{poi.description}}</p>
            </div>
        </l-popup>
    </l-marker>
</template>


<script>
import {
    latLng,
} from "leaflet";
import {
    LMarker,
    LPopup,
    LIcon,
} from 'vue2-leaflet';
export default {
    name: "MapPoint",
    props: {
        poi: {
            type:Object,
            required:true,
            description:"the polygon to show on the map"
        },
        poiIndex: {
            type:Number,
            required:true,
            description:"index of the poi in the store"
        },
        visible: {
            type:Boolean,
            required:false,
            default: true,
            description:"should the point be rendered ?"
        }
    },
    components: {
        LMarker,
        LPopup,
        LIcon,
    },
    data() {
        return {
        }
    },
    methods: {
        getLatLng: function (coords) {
            return latLng(coords[1], coords[0]);
        },
    }
}
</script>
