<template>
    <section>
        <HeaderFormItem
            :instruction="`${
                attribute.instruction ? attribute.instruction : ''
            } ${requiredasterisk}`"
            :description="`${
                attribute.description ? attribute.description : ''
            } `"
            :required="`${requiredmessage} `"
        >
        </HeaderFormItem>
        <div class="flex items-center w-full">
            <i
                :class="
                    attribute.response_private == 1
                        ? 'fas fa-lock px-1 pt-2'
                        : ' '
                "
            ></i>
            <b-field
                :label="attribute.label ? attribute.label : ''"
                :message="attribute.help ? attribute.help : ''"
                class="w-full"
            >
                <b-datetimepicker
                    class="datetimeInput"
                    v-model="selected"
                    :placeholder="
                        attribute.config.placeholder &&
                        attribute.config.placeholder.length > 0
                            ? attribute.config.placeholder[this.$i18n.locale]
                            : ''
                    "
                    icon="calendar-today"
                    :icon-right="selected ? 'close-circle' : ''"
                    icon-right-clickable
                    @icon-right-click="clearDateTime"
                    :locale="locale"
                    :first-day-of-week="firstDayOfWeek"
                    :datepicker="{ showWeekNumber }"
                    :timepicker="{ enableSeconds, hourFormat }"
                    horizontal-time-picker
                >
                </b-datetimepicker>
            </b-field>
        </div>
        <b-field :message="message" type="is-danger font-bold text-center">
        </b-field>
    </section>
</template>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'
import moment from 'moment'

export default {
    name: 'DateTimeFormItem',
    components: { HeaderFormItem },
    mixins: [FormItemMixin],
    data() {
        return {
            selected: null,
            showWeekNumber: false,
            enableSeconds: true
        }
    },
    created() {
        if (this.shouldInitialize()) {
            this.saveUserAnswer({
                id: this.attribute.id,
                type_id: this.attribute.form_attribute_type_id,
                value: this.$store.state.post.post.attributes[this.attribute.id]
                    .value
            }).then(() => {
                this.selected = new Date(
                    this.$store.state.post.post.attributes[
                        this.attribute.id
                    ].value
                )
            })
        } else {
            this.clearDateTime()
        }
    },
    computed: {
        locale() {
            if (this.$i18n.locale == 'fr') {
                this.firstDayOfWeek = 1
                this.hourFormat = '24'
                return 'fr-FR'
            } else {
                this.firstDayOfWeek = 0
                this.hourFormat = '12'
                return 'en-US'
            }
        }
    },
    methods: {
        clearDateTime() {
            this.selected = null
            this.result = null
        }
    },
    watch: {
        selected: function (newValue) {
            if (
                isNaN(newValue) ||
                newValue === 'Invalid date' ||
                newValue === null
            ) {
                this.clearDateTime()
            } else {
                let newDate = moment(String(newValue)).format(
                    'YYYY-MM-DD hh:mm:ss'
                )
                this.result = this.doSave({
                    id: this.attribute.id,
                    type_id: this.attribute.form_attribute_type_id,
                    value: newDate
                })
            }
        }
    }
}
</script>
