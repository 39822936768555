<template>
  <div>
    <div class='flex align-items-stretch justify-content-center'>
      <i
        class='align-self-start'
        :class="
                    attribute.response_private == 1
                        ? 'fas fa-lock mb-6 mr-4'
                        : ' '
                "
      ></i>
      <HeaderFormItem
        :help='attribute.help'
        :instruction="`${
                    attribute.instruction ? attribute.instruction : ''
                } ${requiredasterisk}`"
        :description="`${
                    attribute.description ? attribute.description : ''
                } `"
        :required='`${requiredmessage} `'
      >
      </HeaderFormItem>
    </div>
    <b-field :message='message' type='is-danger font-bold text-center'>
      <MapFindPlace
        :result='result'
        @onLocationSelected='setSelected'
        @onUserLocated='setUserLocation'
      >
      </MapFindPlace>
    </b-field>
    <l-map
      ref='myMap'
      :center='center'
      :zoom='zoom'
      style='height: calc(100vh - 330px); width: 100%; z-index: 1'
      @click='setPoint($event)'
      @update:zoom='updateZoom($event)'
    >
      <l-tile-layer
        :options='optionsZoom'
        :attribution='attribution'
        :url='url'
      />
      <l-geo-json
        v-for='(city, index) in cities'
        :key='index'
        :geojson='city'
        :options='options'
      />

      <l-marker
        v-if='location'
        ref='maPosition'
        :icon='icon'
        :lat-lng='location'
      >
        <l-popup>
          <div>Ma position</div>
        </l-popup>
      </l-marker>

      <l-control
        v-if='pois.length > 0'
        class='leaflet-custom-control w-100 mr-0'
      >
        <i
          class='fas fa-exclamation-triangle text-primary main-icon'
          @click.prevent='showPOIBox = !showPOIBox'
        ></i>

        <div
          v-show='showPOIBox'
          class='content pr-4 pl-4 pt-3 pb-3 btn text-left mr-auto ml-auto shadow w-75'
        >
          <div class='d-flex justify-content-between'>
            <p
              class='font-weight-bold text-dark text-uppercase d-block ml-auto mr-auto mb-3'
            >
              POI
            </p>
            <i
              class='fas fa-times text-gray-500'
              @click.prevent='showPOIBox = false'
            ></i>
          </div>
          <div class='d-flex'>
            <div
              v-for='(poi, index) in pois'
              :key='poi.id'
              class='custom-control custom-switch flex-wrap d-flex flex-row p-0'
            >
              <div class='d-flex flex-wrap border p-2'>
                <i :class='poi.icon' class='text-primary'></i>
                <label
                  :for='poi.id'
                  class='custom-control-label'
                  v-bind:class="{
                                        active: poi.visible,
                                        'bg-primary': poi.visible
                                    }"
                >
                </label>
                <input
                  :id='poi.id'
                  :checked='poi.visible'
                  class='custom-control-input d-block'
                  type='checkbox'
                  @change='poi.visible = !poi.visible'
                />
              </div>
              <div
                class='mt-1 mb-0 text-center text-dark'
                style='font-size: 13px'
              >
                {{ poi.name }}
              </div>
            </div>
          </div>
        </div>
      </l-control>

      <component
        :is='poi.type'
        v-for='(poi, index) in pois'
        :key='poi.id'
        ref='poi'
        :poi='poi'
        :poiIndex='index'
        :visible='poi.visible === true && zoom > 15'
      />
    </l-map>
  </div>
</template>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'

import { mapActions, mapState } from 'vuex'

import MapFindPlace from '@/components/Model/Form/Utils/MapFindPlace'
import MapPoint from '@/components/Model/Form/Utils/MapPoint'
import MapPolygon from '@/components/Model/Form/Utils/MapPolygon'

require('leaflet.snogylop')
import { L, latLng, icon } from 'leaflet'
import {
  LMap,
  LTileLayer,
  LGeoJson,
  LPolygon,
  LMarker,
  LPopup,
  LIcon,
  LControl,
  LTooltip
} from 'vue2-leaflet'

export default {
  name: 'LocationFormItem',
  components: {
    HeaderFormItem,
    MapFindPlace,
    L,
    LMap,
    LTileLayer,
    LPolygon,
    LMarker,
    LGeoJson,
    LPopup,
    LTooltip,
    LIcon,
    LControl,
    'polygon-poi': MapPolygon,
    'point-poi': MapPoint
  },
  mixins: [FormItemMixin],
  watch: {
    //hack to properly set height and with to map container
    activeStep: function(currentStep) {
      if (this.stepIndex === currentStep) {
        this.redraw()
      }
    }
  },
  data() {
    return {
      url: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`,
      attribution:
        '&copy; <a href="javascript:void(0)">OpenStreetMap</a>, &copy; <a href="javascript:void(0)">Mapbox</a> | <a href="javascript:void(0)" target="_blank">Improve the underlying map</a>',
      imagePath: '',
      icon: '',
      showPOIBox: false,
      optionsZoom: {
        maxZoom: 22,
        maxNativeZoom: 20
      }
    }
  },
  created() {
    this.initMap(this.attribute.config.map).then(() => {
      this.init()
    })

    this.result = {
      value: {
        address: '',
        lat: null,
        long: null
      }
    }
  },
  computed: {
    ...mapState('map', {
      postalAddress: (state) => state.postalAddress,
      pois: (state) => state.pois,
      cities: (state) => state.cities,
      options: (state) => state.options,
      location: (state) => state.location
    }),
    ...mapState('wizard', {
      activeStep: (state) => state.activeStep
    }),
    zoom: {
      get() {
        return this.$store.state.map.config.zoom
      },
      set(value) {
        this.$store.commit('map/setZoom', value)
      }
    },
    center: {
      get() {
        return this.$store.state.map.config.center
      },
      set(value) {
        this.$store.commit('map/setCenter', value)
      }
    }
  },
  methods: {
    ...mapActions('map', [
      'initMap',
      'setLocation',
      'getPOI',
      'initDelimiterZone',
      'searchPostalLocation'
    ]),
    redraw: function() {
      this.$nextTick(() => {
        if (this.$refs.myMap)
          this.$refs.myMap.mapObject.invalidateSize()
      })
    },
    init: function() {
      // Préférences
      this.setImagePath()
      this.setIcon()

      // Init map data
      this.getPOI()
      this.initDelimiterZone()

      if (this.result && this.result.value.lat && this.result.value.lon) {
        this.center = [this.result.value.lat, this.result.value.lon]
        this.setLocation({
          lat: this.result.value.lat,
          lng: this.result.value.lon
        })
      }
    },
    setPoint: function(coords) {
      this.searchPostalAddress(coords.latlng)
    },
    setUserLocation: function(coords) {
      this.searchPostalAddress(coords)
    },
    searchPostalAddress: function(coords) {
      this.searchPostalLocation(coords).then(() => {
        this.setLocation(coords).then((latlng) => {
          this.setSelected({
            address: this.postalAddress,
            lat: latlng.lat,
            lon: latlng.lng
          })
        })
      })
    },
    setImagePath: function() {
      this.imagePath = '/img/target.png'
    },
    setIcon: function() {
      this.icon = icon({
        iconUrl: this.imagePath,
        iconSize: [37, 37],
        iconAnchor: [18, 18]
      })
    },
    updateZoom: function(zoom) {
      this.zoom = zoom
    },
    setSelected(location) {
      this.doEmit({
        value: location,
        callback: (result) => {
          this.result = result
        }
      })
    }
  },
  mounted() {
    //hack to properly set height and with to map container
    //this one is used if Location component is in the 1st showed step
    if (this.stepIndex === this.activeStep) {
      this.redraw()
    }
  }
}
</script>

<style scoped></style>
