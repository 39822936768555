<template>
    <div class="block">
        <HeaderFormItem
            :instruction="`${
                attribute.instruction ? attribute.instruction : ''
            } ${requiredasterisk}`"
            :description="`${
                attribute.description ? attribute.description : ''
            } `"
            :required="`${requiredmessage} `"
        >
        </HeaderFormItem>
        <div class="flex-column items-center w-full">
            <div class="mb-2 flex align-items-stretch">
                <i
                    :class="
                        attribute.response_private == 1 ? 'fas fa-lock' : ' '
                    "
                ></i>
                <label class="text-gray-700 text-base font-bold mb-2">
                    {{ attribute.label ? attribute.label : '' }} {{ asterisk }}
                </label>
            </div>
            <div class="flex items-center mb-4">
                <div
                    class="flex items-center ml-2 mr-4"
                    v-for="option in options"
                >
                    <input
                        :id="option"
                        type="checkbox"
                        :value="option"
                        class="checkboxInput w-6 h-6"
                        v-model="result"
                    />
                    <label
                        :for="option"
                        class="ml-2 text-l text-gray-900 dark:text-gray-300"
                        >{{ option }}</label
                    >
                </div>
            </div>
        </div>

        <b-field :message="message" type="is-danger font-bold text-center">
        </b-field>
    </div>
</template>

<style>
input[type='checkbox']:checked + label {
    color: #2563eb;
}
</style>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'

export default {
    name: 'CheckboxFormItem',
    components: {
        HeaderFormItem
    },
    mixins: [FormItemMixin],
    data: function () {
        return {
            options: []
        }
    },
    watch: {
        result: function (newValue) {
            this.saveUserAnswer({
                id: this.attribute.id,
                type_id: this.attribute.form_attribute_type_id,
                value: newValue
            })
        }
    },
    created() {
        this.getOptions()
        this.result = []
    },
    methods: {
        getOptions() {
            if (
                this.attribute.config.options.hasOwnProperty(this.$i18n.locale)
            ) {
                this.options = this.attribute.config.options[this.$i18n.locale]
            } else {
                this.options =
                    this.attribute.config.options[
                        Object.keys(this.attribute.config.options)[0]
                    ]
            }
        }
    }
}
</script>
