<template>
    <div id="category">
        <div class="flex items-center justify-center">
            <i
                :class="
                    attribute.response_private == 1
                        ? 'fas fa-lock pr-2 mb-6 '
                        : ' '
                "
            ></i>
            <HeaderFormItem
                :help="attribute.help"
                :instruction="`${
                    attribute.instruction ? attribute.instruction : ''
                } ${requiredasterisk}`"
                :description="`${
                    attribute.description ? attribute.description : ''
                } `"
                :required="`${requiredmessage} `"
            >
            </HeaderFormItem>
        </div>

        <div class="flex justify-around flex-wrap items-center">
            <div
                v-for="(option, index) in options"
                :key="option.label"
                class="text-center cursor-pointer my-2"
                :class="{
                        ' border-4 border-indigo-700 rounded-md':
                            result &&
                            result.value &&
                            result.value.label === option.label
                    }"
                @click.prevent="setSelected(index)"
            >
                <button
                    class="md:px-6 md:py-4 pt-6 pb-4 max-h-max border-4"
                >
                    <figure class="is-inline-block mb-2 px-6">
                        <i
                            v-if="option.icon"
                            :class="option.icon"
                            class="icon text-indigo-700 inline-flex"
                        ></i>
                        <i
                            v-else
                            class="fa-solid fa-folder icon text-indigo-700 inline-flex"
                        ></i>
                    </figure>

                    <div class="content md:text-base text-sm">
                        {{ option.label }}
                    </div>
                </button>
            </div>
        </div>
        <b-field :message="message" type="is-danger font-bold text-center">
        </b-field>
    </div>
</template>

<style scoped>
#category .field label {
    text-align: center;
}
i.icon {
    font-size: 6em;
}
</style>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'

export default {
    name: 'CategoryFormItem',
    components: { HeaderFormItem },
    mixins: [FormItemMixin],
    data() {
        return {
            options: []
        }
    },
    created() {
        this.getOptions()
    },
    methods: {
        getOptions() {
            this.options = this.attribute.config.options[this.$i18n.locale]
        },
        setSelected(index) {
            this.doEmit({
                value: this.options[index],
                callback: (result) => {
                    this.result = result
                }
            })
        }
    }
}
</script>

<style scoped></style>
