<template>
  <div class='fileUpload'>
    <HeaderFormItem
      :description="attribute.description ? attribute.description : ''"
      :help="attribute.help ? attribute.help : ''"
      :instruction="`${
                attribute.instruction ? attribute.instruction : ''
            } ${requiredasterisk}`"
      :required='`${requiredmessage} `'
    ></HeaderFormItem>
    <div class='flex items-center w-full'>
      <i
        :class="
                    attribute.response_private == 1 ? 'fas fa-lock pt-2' : ' '
                "
      ></i>
      <b-field
        class='w-full'
        type='is-danger'
        :label="`${attribute.label ? attribute.label : ''} ${asterisk}`"
        :message="
                    result && result.length >= max
                        ? `${$t('post.file_upload.max')} ${max}`
                        : ''
                "
      >
        <b-field type='is-info' :message='fileExtension'>
          <b-upload
            v-model='dropFiles'
            drag-drop
            multiple
            expanded
            @input='showFiles'
          >
            <section class='section'>
              <div class='content has-text-centered'>
                <p>
                  <b-icon
                    icon='upload'
                    size='is-large'
                  ></b-icon>
                </p>
                <p>{{ $t('post.file_upload.drop') }}</p>
              </div>
            </section>
          </b-upload>
        </b-field>
      </b-field>
    </div>
    <b-field :message='message' type='is-danger font-bold text-center'>
    </b-field>
    <div
      v-if="this.attribute.config.type === 'pictures'"
      class='flex flex-row grid grid-cols-5'
    >
      <div
        v-for='(file, index) in result'
        class='bg-white rounded border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 mx-10 relative mb-10'
        :key='index'
      >
        <img
          class='object-cover h-48 w-96 rounded'
          :src='file.encodedFile'
        />
        <i
          @click.prevent='deleteDropFile(index)'
          class='cursor-pointer absolute top-0 right-0 bg-red-500 text-white p-2 rounded hover:bg-red-800 fa fa-trash'
          aria-hidden='true'
        ></i>
      </div>
    </div>

    <div v-else>
      <div class='tags'>
                <span
                  class='tag is-primary is-large'
                  v-for='(file, index) in result'
                  :key='index'
                >
                    {{ file.name }}
                    <button
                      class='delete is-medium ml-2'
                      type='button'
                      @click='deleteDropFile(index)'
                    ></button>
                </span>
      </div>
    </div>
  </div>
</template>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'
import Compressor from 'compressorjs'
import { mapState } from 'vuex'

export default {
  name: 'FileUploadFormItem',
  components: { HeaderFormItem },
  mixins: [FormItemMixin],
  watch: {
    result: function(newValue) {
      this.doSave({
        id: this.attribute.id,
        type_id: this.attribute.form_attribute_type_id,
        value: newValue
      })
    }
  },
  data() {
    return {
      dropFiles: [],
      isPicturesUpload: this.attribute.config.type === 'pictures',
      min: this.attribute.config.min,
      max: this.attribute.config.max,
      maxWidth: 1200,
      maxHeight: 1200,
      files: [],
      baseUrl: this.$store.state.baseURL,
      token: this.$store.state.auth.token
    }
  },
  created() {
    if (this.$route.name === 'EditPost') {
      this.result = []
    }
    if (this.post && this.post.answers) {
      this.getFilesFormAnswers()
    }
  },
  computed: {
    fileExtension: function() {
      return (
        (this.attribute.config['accepted-ext']
          ? this.$t('post.file_upload.file_extension') +
          this.attribute.config['accepted-ext'].join(', ') +
          '.'
          : '') +
        ' Max ' +
        this.max +
        ' fichiers'
      )
    },
    ...mapState('post', {
      post: (state) => state.newPost
    })
  },
  methods: {
    deleteDropFile(index) {
      this.result.splice(index, 1)
      this.dropFiles.splice(index, 1)
    },
    getFilesFormAnswers() {
      this.post.answers.forEach((answer) => {
        if (
          answer.form_attribute_type_id == 12 &&
          answer.form_attribute_id === this.attribute.id
        ) {
          answer.value.forEach((value_id) => {
            if (value_id) {
              this.files.push(
                this.post.medias.filter(
                  (media) => media.id === value_id
                )[0]
              )
            }
          })
        }
      })
      if (this.files && this.files.length > 0) {
        this.files.forEach((file) => {
          this.result.push({
            name: file.name,
            ext: file.name.split('.').pop(),
            encodedFile: `${this.baseUrl}/storage/${file.id}?token=${this.token}`
          })
        })
      }
    },
    showFiles(files) {
      this.result = []
      files.forEach((file) => {
        if (this.result.length >= this.max) {
          return
        }

        var fileExt = file.name.split('.').pop()
        var acceptedExt = this.attribute.config['accepted-ext']

        if (acceptedExt && !acceptedExt.includes(fileExt)) {
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$t('error.validation.file_upload'),
            type: 'is-danger'
          })
          return
        }

        if (file['size'] > 6250000) {
          this.$buefy.toast.open({
            duration: 5000,
            message: this.$t('error.validation.file_size_max'),
            type: 'is-danger'
          })
          return
        }
        if (this.isPicturesUpload === true) {
          new Compressor(file, {
            quality: 0.8,
            maxWidth: this.maxWidth,
            maxHeight: this.maxHeight,
            success: (compressedImage) => {
              this.encodeFileToBase64(compressedImage)
            },
            error(e) {
              console.log(e.message)
            }
          })
        } else {
          this.encodeFileToBase64(file)
        }
      })
    },
    encodeFileToBase64(file) {
      if (this.result === null) {
        this.result = []
      }
      var fr = new FileReader()
      fr.onload = (e) => {
        this.result.push({
          name: file.name,
          ext: file.name.split('.').pop(),
          encodedFile: e.target.result
        })
      }
      fr.readAsDataURL(file)
    }
  }
}
</script>

<style scoped></style>
