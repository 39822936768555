<template>
    <section>
        <HeaderFormItem
            :instruction="`${
                attribute.instruction ? attribute.instruction : ''
            } ${requiredasterisk}`"
            :description="`${
                attribute.description ? attribute.description : ''
            } `"
            :required="`${requiredmessage} `"
        >
        </HeaderFormItem>
        <div class="flex items-center w-full">
            <i
                :class="
                    attribute.response_private == 1
                        ? 'fas fa-lock px-1 pt-2'
                        : ' '
                "
            ></i>
            <b-field
                :label="`${attribute.label ? attribute.label : ''} ${asterisk}`"
                :message="attribute.help ? attribute.help : ''"
                class="w-full"
            >
                <b-numberinput
                    v-model="result"
                    controls-alignment="right"
                    controls-position="compact"
                ></b-numberinput>
            </b-field>
        </div>
        <b-field :message="message" type="is-danger font-bold text-center">
        </b-field>
    </section>
</template>

<script>
import FormItemMixin from '../FormItemMixin'
import HeaderFormItem from '../HeaderFormItem'

export default {
    name: 'NumberFormItem',
    mixins: [FormItemMixin],
    components: { HeaderFormItem },
    data: function () {
        return {}
    },
    created() {
        if (this.shouldInitialize()) {
            this.saveUserAnswer({
                id: this.attribute.id,
                type_id: this.attribute.form_attribute_type_id,
                value: this.$store.state.post.post.attributes[this.attribute.id]
                    .value
            }).then(() => {
                this.result =
                    this.$store.state.post.post.attributes[
                        this.attribute.id
                    ].value
            })
        }
    },
    watch: {
        result: function (newValue) {
            this.doSave({
                id: this.attribute.id,
                type_id: this.attribute.form_attribute_type_id,
                value: newValue
            })
        }
    }
}
</script>
