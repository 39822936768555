<template>
    <section class="mt-2">
        <b-steps
            v-model="activeStep"
            :animated="isAnimated"
            :has-navigation="hasNavigation"
            :icon-next="nextIcon"
            :icon-prev="prevIcon"
            :label-position="labelPosition"
            :mobile-mode="mobileMode"
            :rounded="isRounded"
        >
            <b-step-item
                v-for="(step, index) in form.steps"
                :key="step.id"
                :clickable="isStepsClickable[index - 1]"
                :label="step.name"
                :step="1 + index"
            >
                <FormStep ref="step" :step="step" :stepIndex="index" />
            </b-step-item>

            <b-step-item icon="check">
                <FormStepSuccess></FormStepSuccess>
            </b-step-item>

            <template #navigation="{ previous, next }">
                <b-button
                    v-show="activeStep < form.steps.length"
                    ref="prevBtn"
                    :disabled="previous.disabled"
                    type="is-primary is-light"
                    @click.prevent="previous.action"
                >
                    {{ $t('wizard.previous') }}
                </b-button>
                <b-button
                    id="nextBtn"
                    v-show="activeStep < form.steps.length - 1"
                    ref="nextBtn"
                    :disabled="next.disabled"
                    outlined
                    type="is-primary is-light"
                    @click.native="scrollToTop()"
                    @click.prevent="goNext(next)"
                >
                    {{ $t('wizard.next') }}
                </b-button>
                <b-button
                  id="sendBtn"
                    v-show="activeStep === form.steps.length - 1"
                    ref="nextBtn"
                    type="is-primary"
                    @click.prevent="sendPostHandler"
                >
                    {{ $t('wizard.send') }}
                </b-button>
            </template>
        </b-steps>

        <b-loading
            v-model="sending"
            :can-cancel="false"
            :is-full-page="true"
        ></b-loading>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FormStep from '@/components/Model/Form/FormStep'
import FormStepSuccess from '@/components/Model/Form/FormStepSuccess'
import ToastableMixin from '../../UI/Utils/ToastableMixin'

export default {
    name: 'Wizard',
    mixins: [ToastableMixin],
    components: {
        FormStepSuccess,
        FormStep
    },
    data() {
        return {
            showSocial: false,
            isAnimated: true,
            isRounded: true,

            customNavigation: false,
            isProfileSuccess: false,

            prevIcon: 'chevron-left',
            nextIcon: 'chevron-right',
            labelPosition: 'bottom',
            mobileMode: 'minimalist'
        }
    },
    created() {
        this.$store.state.wizard.activeStep = 0
        this.form.steps.forEach((step) => {
            this.$store.state.wizard.isStepsClickable.push(false)
        })
    },
    computed: {
        ...mapState('wizard', {
            isStepsClickable: (state) => state.isStepsClickable,
            hasNavigation: (state) => state.hasNavigation
        }),
        ...mapState('post', {
            post: (state) => state.post
        }),
        activeStep: {
            get() {
                return this.$store.state.wizard.activeStep
            },
            set(value) {
                this.$store.commit('wizard/setCurrentStep', value)
            }
        },
        sending: {
            get() {
                return this.$store.state.post.sending
            },
            set(value) {
                this.$store.commit('post/setSending', value)
            }
        },
        ...mapGetters('form', {
            form: 'getForm'
        })
    },
    methods: {
        ...mapActions('form', {
            validate: 'validate'
        }),
        ...mapActions('post', ['sendPost', 'updatePost']),
        goNext(next) {
            this.$refs.step[this.activeStep]
                .validate()
                .then(() => {
                    next.action()
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        sendPostHandler() {
            //validate last step before sending post
            this.$refs.step[this.activeStep]
                .validate()
                .then(() => {
                    this.sending = true
                    let endpoint =
                        this.post.id && this.post.id > 0
                            ? this.updatePost(this.form.id)
                            : this.sendPost(this.form.id)
                    endpoint
                        .then(() => {
                            this.activeStep = 4
                        })
                        .catch((error) => {
                            if (
                                error.response &&
                                error.response.data &&
                                error.response.data.errors
                            ) {
                                this.handleValidationError(error.response.data)
                                this.showValidationError(error.response.data)
                            }else{
                              this.$store.dispatch('dispatchError', error)
                            }
                        })
                        .finally(() => {
                            this.sending = false
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        handleValidationError(data) {
            var minIndexStep = -1

            for (const [key, value] of Object.entries(data.errors)) {
                var error = key.split('.')
                var attributeId = error[error.length - 1]

                for (var i = 0; i < this.form.steps.length; i++) {
                    var step = this.form.steps[i]
                    var indexAttribute = step.attributes.findIndex(
                        (attribute) => attribute.id === parseInt(attributeId)
                    )

                    if (indexAttribute >= 0) {
                        if (i < minIndexStep || minIndexStep === -1)
                            minIndexStep = i

                        break
                    }
                }
            }

            if (minIndexStep !== -1) this.activeStep = minIndexStep
        },
        scrollToTop() {
            window.scrollTo(0, 0)
        }
    }
}
</script>
