<template>
    <div class="pb-2">
        <b-field>
            <b-autocomplete
                id="adress"
                class="addressInput"
                v-model="result.value.address"
                :data="locationsResult"
                :loading="isFetching"
                :open-on-focus="true"
                expanded
                field="value"
                icon="map-marker-alt"
                icon-right="close-circle"
                icon-right-clickable
                placeholder="Adresse"
                @select="(option) => setSelected(option)"
                @typing="getAsyncData"
            >
                <template slot-scope="props">
                    <div class="media">
                        <div class="media-left">
                            <i class="fas fa-map-marker-alt is-primary"></i>
                        </div>
                        <div class="media-content">
                            {{ props.option.address }}
                        </div>
                    </div>
                </template>
            </b-autocomplete>

            <p class="control">
                <b-button
                    id="geolocation"
                    class="h-full text-white"
                    icon-left="street-view"
                    :label="labelposition"
                    type="is-primary"
                    @click.prevent="locateUser"
                />
            </p>
        </b-field>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { latLng } from 'leaflet'

export default {
    name: 'LocationFormItem',
    components: {},
    props: ['result'],
    data() {
        return {
            locationsResult: [],
            isFetching: false,
            labelposition: '',
            resultat: ''
        }
    },
    created() {
        this.resultat = this.result
        if (screen.width > 700) {
            this.labelposition = this.$t('location.use_current_location')
        } else {
            this.labelposition = this.$t('location.use_current_location')
        }
    },
    computed: {
        postalAddress: {
            get() {
                return this.$store.state.map.postalAddress
            },
            set(value) {
                this.$store.commit('map/setPostalAddress', value)
            }
        }
    },
    methods: {
        ...mapActions('map', [
            'setLocation',
            'setUserLocation',
            'searchPlacesFromPostalAddress'
        ]),
        getAsyncData: function (name) {
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            this.resultat.value.address = name
            this.setSelected({ address: name })
            this.timeout = setTimeout(() => {
                this.searchPlaces(name)
            }, 600)
        },

        locateUser: function () {
            this.setUserLocation().then((coords) => {
                this.$emit('onUserLocated', coords)
            })
        },

        searchPlaces: function (name) {
            this.postalAddress = name

            this.isFetching = true
            if (this.postalAddress.length > 0)
                this.searchPlacesFromPostalAddress(this.postalAddress)
                    .then((results) => {
                        if (results.length > 0) this.locationsResult = results
                        else {
                            this.resultat.value.address = name
                            this.setSelected({ address: name })
                        }
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
        },

        setSelected: function (location) {
            this.postalAddress = location.address

            this.$emit('onLocationSelected', location)

          if(location.lat && location.lon){
            this.setLocation(latLng(location.lat, location.lon)).then(() => {
              var config = this.$store.state.map.config
              config.center = [location.lat, location.lon]

              this.$store.commit('map/setConfig', config)
            })
          }

        }
    },
    mounted() {}
}
</script>

<style scoped></style>
