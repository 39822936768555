<template>
    <div class="box mt-3">
        <section class="hero">
            <div class="hero-body has-text-centered">
                <p class="title has-text-primary">
                    {{ step.name }}
                </p>
                <p class="subtitle">
                    {{ step.content }}
                </p>
            </div>
        </section>

        <section class="section">
            <component
                v-for="(attribute, index) in step.attributes"
                :key="attribute.id"
                :is="attribute.form_attribute_type.name"
                ref="attributes"
                :attribute="attribute"
                :attributeIndex="index"
                :stepIndex="stepIndex"
                class="mb-4"
            />
        </section>
    </div>
</template>

<style>
.hero-body {
    padding-top: 0;
}
.b-numberinput input[type='number'] {
    width: 90%;
}
.button.is-primary {
    height: 100%;
    border: 1px solid #c4c4c4;
    color: white;
}
.button.is-primary i {
    color: white;
}

.select select {
    width: 100%;
}
.select {
    width: 100%;
}
@media (min-width: 700px) {
    .is-danger.input {
        width: 96%;
    }
    .input {
        border: 1px solid #c4c4c4;
        border-radius: 0.2em;
        width: 98%;
    }
    .control.has-icons-left .input {
        width: 40%;
    }
}
@media (max-width: 700px) {
    .is-danger.input {
        width: 85%;
    }
    .input {
        border: 1px solid #c4c4c4;
        border-radius: 0.2em;
        width: 92%;
    }
    .control.has-icons-left .input {
        width: 90%;
    }
}
#adress.input {
    width: 93%;
    border-radius: 0.2em;
}
</style>

<script>
import LocationFormItem from './FormItem/LocationFormItem'
import CategoryFormItem from './FormItem/CategoryFormItem'
import { mapActions } from 'vuex'
import GdprFormItem from './FormItem/GdprFormItem'
import SmallTextFormItem from './FormItem/SmallTextFormItem'
import EmailFormItem from './FormItem/EmailFormItem'
import MarkdownFormItem from './FormItem/MarkdownFormItem'
import FileUpload from './FormItem/FileUploadFormItem'
import RatingFormItem from './FormItem/RatingFormItem'
import SelectFormItem from './FormItem/SelectFormItem'
import CheckboxFormItem from './FormItem/CheckboxFormItem'
import RadioFormItem from './FormItem/RadioFormItem'
import DateFormItem from './FormItem/DateFormItem'
import DateTimeFormItem from './FormItem/DateTimeFormItem'
import NumberFormItem from './FormItem/NumberFormItem'
import TextareaFormItem from './FormItem/TextareaFormItem'

export default {
    name: 'FormStep',
    props: {
        step: {
            required: true,
            type: Object,
            description: 'A step with all its attributes'
        },
        stepIndex: {
            required: true,
            type: Number,
            description: 'Current step index from the steps array in Wizard'
        }
    },
    components: {
        location: LocationFormItem,
        category: CategoryFormItem,
        gdpr: GdprFormItem,
        smalltext: SmallTextFormItem,
        email: EmailFormItem,
        markdown: MarkdownFormItem,
        'file-upload': FileUpload,
        rating: RatingFormItem,
        'select-field': SelectFormItem,
        checkbox: CheckboxFormItem,
        radio: RadioFormItem,
        date: DateFormItem,
        datetime: DateTimeFormItem,
        number: NumberFormItem,
        bigtext: TextareaFormItem
    },

    created() {
        let shouldInitialize = false

        if (
            !this.$store.state.post.post ||
            !this.$store.state.post.post.attributes
        ) {
            shouldInitialize = true
        } else {
            let keys = Object.keys(this.$store.state.post.post.attributes)
            if (keys.length <= 0) {
                shouldInitialize = true
            }
        }

        if (shouldInitialize) {
            this.step.attributes.map(
                (attribute) =>
                    (this.$store.state.post.post.attributes[attribute.id] = {
                        id: attribute.id,
                        step: this.step.id
                    })
            )
        }
    },
    methods: {
        validate() {
            return new Promise((resolve, reject) => {
                var isValid = !this.$refs.attributes.some(
                    (attribute) => attribute.validate() === false
                )
                this.$store.commit('wizard/setIsStepsClickable', {
                    index: this.$store.state.wizard.activeStep,
                    value: isValid
                })

                isValid ? resolve() : reject()
            })
        }
    }
}
</script>

<style scoped></style>
