<template>
    <div>
        <div class="has-text-centered mb-6 content">
            <h3 class="has-text-primary-dark">{{ instruction }}</h3>
            <p>{{ description }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderFormItem',
    props: {
        instruction: {
            required: false,
            type: String,
            default: '',
            description: 'Instructions to help user choice'
        },
        description: {
            required: false,
            type: String,
            default: '',
            description: 'Describe the current attribute'
        }
    }
}
</script>
